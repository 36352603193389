export default [
    {
        html: 'YNGMI',
        color: 'green',
        result: 0,
        resultMessage: 'OUCH!'
    },
    {
        html: 'BANKRUPT',
        color: 'black',
        result: 0,
        resultMessage: 'OUCH!'
    },
    {
        html: '<span>2x</span> STAKE',
        color: 'pink',
        result: 2,
        resultMessage: 'WIN!'
    },
    {
        html: 'BANKRUPT',
        color: 'black',
        result: 0,
        resultMessage: 'OUCH!'
    },
    {
        html: '<span>2x</span> STAKE',
        color: 'pink',
        result: 2,
        resultMessage: 'WIN!'
    },
    {
        html: 'BANKRUPT',
        color: 'black',
        result: 0,
        resultMessage: 'OUCH!'
    },
    {
        html: '<span>2x</span> STAKE',
        color: 'pink',
        result: 2,
        resultMessage: 'WIN!'
    },
    {
        html: 'BANKRUPT',
        color: 'black',
        result: 0,
        resultMessage: 'OUCH!'
    },
    {
        html: '<span>2x</span> STAKE',
        color: 'pink',
        result: 2,
        resultMessage: 'WIN!'
    },
    {
        html: 'BANKRUPT',
        color: 'black',
        result: 0,
        resultMessage: 'OUCH!'
    },
    {
        html: '<span>2x</span> STAKE',
        color: 'pink',
        result: 2,
        resultMessage: 'WIN!'
    },
    {
        html: 'BANKRUPT',
        color: 'black',
        result: 0,
        resultMessage: 'OUCH!'
    },
    {
        html: '<span>2x</span> STAKE',
        color: 'pink',
        result: 2,
        resultMessage: 'WIN!'
    },
    {
        html: 'BANKRUPT',
        color: 'black',
        result: 0,
        resultMessage: 'OUCH!'
    },
    {
        html: '<span>2x</span> STAKE',
        color: 'pink',
        result: 2,
        resultMessage: 'WIN!'
    },
    {
        html: 'BANKRUPT',
        color: 'black',
        result: 0,
        resultMessage: 'OUCH!'
    },
    {
        html: '<span>2x</span> STAKE',
        color: 'pink',
        result: 2,
        resultMessage: 'WIN!'
    },
    {
        html: 'BANKRUPT',
        color: 'black',
        result: 0,
        resultMessage: 'OUCH!'
    },
    {
        html: '<span>2x</span> STAKE',
        color: 'pink',
        result: 2,
        resultMessage: 'WIN!'
    },
    {
        html: 'BANKRUPT',
        color: 'black',
        result: 0,
        resultMessage: 'OUCH!'
    },
    {
        html: '<span>2x</span> STAKE',
        color: 'pink',
        result: 2,
        resultMessage: 'WIN!'
    },
]
