import { h } from 'preact'
import { useContext } from 'preact/hooks'
import { AppContext, GameContext, ToastContext } from '../context'
import ConnectWallet from './ConnectWallet'
import StakeOptions from './StakeOptions'
import RiskOptions from './RiskOptions'
import SidebarNavigation from './SidebarNavigation'
import Logo from './../../img/logo.svg'
import {
    API_DOMAIN,
    GAME_STATES,
    GAME_WALLET
} from '../constants'
import {
    Transaction,
    SystemProgram,
    LAMPORTS_PER_SOL
} from '@solana/web3.js'

export default function() {
    const {
        provider,
        connection,
        isConnected,
        pubKey
    } = useContext(AppContext)

    const {
        gameState,
        setGameState,
        gameResult,
        setGameResult,
        gameRisk,
        gameStake,
    } = useContext(GameContext)

    const addToast = useContext(ToastContext)

    const createTransaction = async () => {
        if (!pubKey) {
            return
        }

        let transaction = new Transaction().add(
            SystemProgram.transfer({
                fromPubkey: pubKey,
                toPubkey: GAME_WALLET,
                lamports: gameStake * LAMPORTS_PER_SOL
            })
        )

        transaction.feePayer = pubKey
        transaction.recentBlockhash = (
            await connection.getRecentBlockhash()
        ).blockhash

        return transaction
    }

    const sendTransaction = async () => {
        const transaction = await createTransaction()

        if (!transaction) {
            throw new Error('Failed to create transaction')
        }

        const { signature } = await provider.signAndSendTransaction(transaction)
        const confirmed = await connection.confirmTransaction(signature)

        return { signature, confirmed }
    }

    const requestGame = async () => {
        setGameState(GAME_STATES.REQUESTED)
        setGameResult(null)

        try {
            const { signature } = await sendTransaction()

            setGameState(GAME_STATES.PLAYING)

            const response = await fetch(`${API_DOMAIN}/spin/play`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    wallet: pubKey.toString(),
                    tx: signature,
                    risk: gameRisk,
                    stake: gameStake * LAMPORTS_PER_SOL
                })
            })

            const result = await response.json()

            setGameResult(result)
        } catch (err) {
            setGameState(GAME_STATES.ERROR)
            addToast('There was an error with the transaction')
        }
    }

    return (
        <aside class="sidebar">
            <div class="sidebar__logo">
                <Logo />
            </div>

            <h1 class="sidebar__title">Sol Spin</h1>

            { !provider ?
                <a href="https://phantom.app/download" target="_blank" rel="noopener noreferrer" class="btn  btn--block">Install Phantom</a>
            :
                <ConnectWallet />
            }

            <div class={ `game-options  ${!isConnected || [GAME_STATES.PLAYING, GAME_STATES.REQUESTED].includes(gameState) ? 'disabled' : ''}` }>
                <StakeOptions/>
                <RiskOptions/>

                <button class="btn  btn--block" onClick={() => requestGame(pubKey)}>
                    {(() => {
                        switch (gameState) {
                            case GAME_STATES.ERROR:
                                return 'Error! Try again'
                            default:
                                return `Let's Spin`
                        }
                    })()}
                </button>
                <p class="game-notes">
                    All winnings will be paid out automatically, this may take up to a minute depending on Solana congestion. <a href="#">Click here</a> if you think you're missing a payout.
                </p>
            </div>

            <SidebarNavigation/>
        </aside>
    )

}
