import { createContext } from 'preact'
import { useCallback, useEffect, useState } from 'preact/hooks'

export const Context = createContext()

export const Provider = props => {
    const { children } = props

    const [toasts, setToasts] = useState([])

    useEffect(() => {
        if (toasts.length > 0) {
            const timer = setTimeout(() => {
                setToasts(toasts => toasts.slice(1))
            }, 4500)

            return () => clearTimeout(timer)
        }
    }, [toasts])

    const addToast = useCallback(function(toast) {
        setToasts(toasts => [...toasts, toast])
    }, [setToasts])

    return <Context.Provider value={addToast}>
        {children}

        <div className="toast-container">
            {toasts.map(toast => (
                <div className="toast" key={toast}>
                    {toast}
                </div>
            ))}
        </div>
    </Context.Provider>
}

export const { Consumer } = Context
