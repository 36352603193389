export default function() {

    return (
        <nav class="sidebar__menu">
            <ul class="sidebar__nav">
                <li class="sidebar__nav-item">
                    <a href="https://solkin.gs/" target="_blank">Home</a>
                </li>
                <li class="sidebar__nav-item">
                    <a href="https://solkin.gs/#our-games" target="_blank">Games</a>
                </li>
                <li class="sidebar__nav-item">
                    <a href="https://solkin.gs/#roadmap" target="_blank">Roadmap</a>
                </li>
                <li class="sidebar__nav-item">
                    <a href="https://solkin.gs/#faqs" target="_blank">FAQS</a>
                </li>
            </ul>
        </nav>
    )

}
