import {
    gsap,
    Power1
} from 'gsap'

const buildIntroSpinAnimation = el => {
    const anim = gsap.timeline({ paused: true, repeat: -1 })

    anim.to(el, { scale: 1.1, ease: Power1.easeOut, duration: .2 }, 1)
    anim.to(el, { scale: 1, ease: Power1.easeOut, duration: .4 }, 1.2)
    anim.to(el, { rotation: -1080, ease: Power1.easeOut, duration: 1 }, 1.6)

    return anim
}

const buildIntroLightAnimation = el => {
    const anim = gsap.timeline({ paused: true, repeat: -1 })
    const q = gsap.utils.selector(el)

    // Track time
    let time = .01
    let txtTime = 0.01

    q('path').forEach(segment => {
        // Turn on
        anim.set(segment, { opacity: 1 }, time)

        // Increment time
        time += 0.08

        // Turn off
        anim.set(segment, { opacity: .5 }, time)
    })

    q('.wheel__segments-txt__holder').forEach(txtsegment => {
        // Turn on
        /*anim.set(txtsegment, { opacity: 1, 'text-shadow' : '0px 0px 3px #fff, 0px 0px 45px #fff,  0px 0px 300px #fff'}, txtTime)

        // Increment time
        txtTime += 0.08

        // Turn off
        anim.set(txtsegment, { opacity: .75, 'text-shadow' : 'none' }, txtTime)*/

        anim.set(txtsegment, { opacity: 1, 'text-shadow' : '0px 0px 2px #fff, 0px 0px 30px #fff'}, txtTime)

        // Increment time
        txtTime += 0.08

        // Turn off
        anim.set(txtsegment, { opacity: .75, 'text-shadow' : 'none' }, txtTime)
    })

    return anim
}

export {
    buildIntroSpinAnimation,
    buildIntroLightAnimation
}

