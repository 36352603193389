import { h } from 'preact'
import { useContext } from 'preact/hooks'
import { GameContext } from '../context'
import { GAME_STATES } from '../constants'
import WheelsConfig from './../wheels'
import WheelIntro from './WheelIntro'
import WheelPlay from './WheelPlay'

export default function() {
    const {
        gameRisk,
        gameState
    } = useContext(GameContext)

    const segments = WheelsConfig[gameRisk]

    return (
        <main class="main">
            <div class="spinner">
                <div class={ `wheel  ${[GAME_STATES.REQUESTED].includes(gameState) ? 'disabled' : ''}` }>
                    <div class="wheel__pointer"></div>

                    { gameState < GAME_STATES.PLAYING ?
                        <WheelIntro segments={segments} />
                    :
                        <WheelPlay segments={segments} />
                    }
                </div>
            </div>
        </main>
    )

}
