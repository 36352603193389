import { PublicKey } from '@solana/web3.js'

const { RPC_ENDPOINT, HOUSE_WALLET, API_DOMAIN } = process.env

const GAME_WALLET = new PublicKey(HOUSE_WALLET)

const GAME_STATES = {
    EMPTY: 0,
    REQUESTED: 1,
    PLAYING: 2,
    COMPLETE: 3,
    ERROR: 4
}

const GAME_STAKES = [
    0.001,
    0.01,
    0.1
]

const GAME_RISKS = {
    FIFTY: 'Double<br/>Or Nothing',
    STANDARD: 'Standard',
    DEGEN: 'Degen'
}

export {
    API_DOMAIN,
    RPC_ENDPOINT,
    GAME_WALLET,
    GAME_STATES,
    GAME_STAKES,
    GAME_RISKS
}
