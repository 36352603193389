import { h } from 'preact'
import { useContext } from 'preact/hooks'
import { AppContext } from '../context'

export default function() {
    const {
        provider,
        pubKey
    } = useContext(AppContext)

    return (
        <div class="wallet-connect">
            { pubKey ?
                <button class="btn  btn--block" disabled>{pubKey.toString().substr(0, 8)}...</button>
            :
                <button class="btn  btn--block" onClick={() => provider.connect()}>Connect Wallet</button>
            }
        </div>
    )

}
