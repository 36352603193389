import { createContext } from 'preact'
import { useState } from 'preact/hooks'
import { Connection } from '@solana/web3.js'
import { RPC_ENDPOINT } from '../constants'

export const Context = createContext()

export const Provider = props => {
    const { children } = props

    const provider = 'solana' in window && window.solana.isPhantom ? window.solana : null
    const connection = new Connection(RPC_ENDPOINT)

    const [isConnected, setIsConnected] = useState(false)
    const [pubKey, setPubKey] = useState(null)

    const appContext = {
        provider,
        connection,
        isConnected,
        setIsConnected,
        pubKey,
        setPubKey
    }

    return <Context.Provider value={appContext}>{children}</Context.Provider>
}

export const { Consumer } = Context
