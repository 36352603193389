import { h } from 'preact'
import { useContext } from 'preact/hooks'
import { GameContext } from '../context'
import { GAME_RISKS } from '../constants'

export default function() {
    const {
        gameRisk,
        setGameRisk
    } = useContext(GameContext)

    return (
        <div class="game-option">
            <h3 class="game-option__title">Select your wheel:</h3>
            <div class="game-option__group">
                { Object.entries(GAME_RISKS).map(([ key, value ]) => {
                    return (
                        <button
                            class={ `btn  ${key !== gameRisk ? 'btn--ghost  bg-brand-secondary' : 'bg-brand-secondary current'}` }
                            onClick={() => setGameRisk(key)}
                            dangerouslySetInnerHTML={{ __html: value }}
                        />
                    )
                }) }
            </div>
        </div>
    )

}
