import { h, render } from 'preact'
import { useEffect, useContext } from 'preact/hooks'
import {
    Sidebar,
    Spinner
} from './components'
import {
    AppContextProvider,
    AppContext,
    GameContextProvider,
    ToastContextProvider
} from './context'

const App = () => {
    const {
        provider,
        setIsConnected,
        setPubKey
    } = useContext(AppContext)

    useEffect(() => {
        if (!provider) {
            return
        }

        // Eagerly attempt to connect wallet
        provider.connect({ onlyIfTrusted: true }).catch(err => {
            // Stay quiet
        })

        provider.on('connect', pubKey => {
            setIsConnected(true)
            setPubKey(pubKey)
        })

        provider.on('disconnect', () => {
            setIsConnected(false)
            setPubKey(null)
        })

        provider.on('accountChanged', pubKey => {
            setPubKey(pubKey)

            if (!pubKey) {
                provider.connect()
            }
        })

        return () => provider.disconnect()
    }, [provider])

    return (
        <GameContextProvider>
            <ToastContextProvider>
                <Sidebar/>
                <Spinner/>
            </ToastContextProvider>
        </GameContextProvider>
    )
}

const Root = () => {
    return (
        <AppContextProvider>
            <App/>
        </AppContextProvider>
    )

}

// Temporary fix to ensure Phantom wallet is available to window
window.addEventListener('load', () => render(<Root/>, document.getElementById('app')))
