import { createContext } from 'preact'
import { useState } from 'preact/hooks'
import {
    GAME_STATES,
    GAME_STAKES
} from '../constants'

export const Context = createContext()

export const Provider = props => {
    const { children } = props

    // Get middle stake
    const defaultStake = GAME_STAKES[ Math.floor(GAME_STAKES.length/2) ];

    const [gameStake, setGameStake] = useState(defaultStake)
    const [gameRisk, setGameRisk] = useState('STANDARD')
    const [gameState, setGameState] = useState(GAME_STATES.EMPTY)
    const [gameResult, setGameResult] = useState(null)

    const gameContext = {
        gameStake,
        setGameStake,
        gameRisk,
        setGameRisk,
        gameState,
        setGameState,
        gameResult,
        setGameResult,
    }

    return <Context.Provider value={gameContext}>{children}</Context.Provider>
}

export const { Consumer } = Context
