import { createRef, h } from 'preact'
import { useEffect, useContext, useState, useRef, useMemo } from 'preact/hooks'
import { GameContext } from '../context'
import WheelSegments from './WheelSegments'
import {
    gsap,
    Power1,
    Power2,
    Power3,
    Power4
} from 'gsap'
import TickMp3 from '../../audio/tick.mp3'
import { GAME_STATES } from '../constants'
import { LAMPORTS_PER_SOL } from '@solana/web3.js'

const randomSpinLength = gsap.utils.random(20, 24, true)

const easeOptions = [Power1.easeOut, Power2.easeOut, Power3.easeOut, Power4.easeOut]
const randomEaseIndex = gsap.utils.random(0, easeOptions.length - 1, true)

const tickAudio = new Audio(TickMp3)

let activeSegment = 0

function updateActiveSegment(el, paths, txts) {
    const rotation = parseInt(el._gsap.rotation)
    const offset = Math.floor((rotation / 360))

    // Calculate active segment
    let calcSegment = Math.ceil(((rotation - (offset * 360)) - (180 / 21)) / (360 / 21))

    if (calcSegment < 1 || calcSegment > 20) {
        calcSegment = 0
    }

    // Return if segment hasn't changed
    if (activeSegment === calcSegment) {
        return
    }

    // Tick?
    tickAudio.currentTime = 0
    tickAudio.play()

    // Light
    gsap.set(txts[activeSegment], { opacity: .75, 'text-shadow' : 'none'})
    gsap.set(paths[activeSegment], { opacity: .5 })


    gsap.set(txts[calcSegment], { opacity: 1, 'text-shadow' : '0px 0px 2px #fff, 0px 0px 30px #fff'})
    gsap.set(paths[calcSegment], { opacity: 1 })

    /*gsap.set(txts[calcSegment], { opacity: 1, 'text-shadow' : '0px 0px 3px #fff, 0px 0px 40px #fff'})
    gsap.set(txts[calcSegment], { opacity: 1, 'text-shadow' : '0px 0px 3px #fff'})*/

    activeSegment = calcSegment
}

export default function({ segments }) {
    const {
        gameResult,
        gameState,
        setGameState,
    } = useContext(GameContext)

    const [centreText, setCentreText] = useState('')

    // Refs
    const centre = useRef()
    const wheelSegments = useRef()

    useEffect(() => {
        switch (gameState) {
            case GAME_STATES.ERROR:
                setCentreText('SOL SPIN')
                break;
        }
    }, [gameState])

    useEffect(() => {
        if (!gameResult) {
            return
        }

        const { result, payout } = gameResult
        const { resultMessage } = segments[result]
        const resultRotation = (360 * 4) + (result * (360 / 21))

        // Selector
        const q = gsap.utils.selector(wheelSegments.current.base)

        // Animation
        const spinAnim = gsap.timeline({ paused: true })
        const spinLength = randomSpinLength()
        const easeIndex = randomEaseIndex()

        spinAnim.set(wheelSegments.current.base, { rotation: 0 })
        spinAnim.call(() => setCentreText('<span class="spin-countdown">3</span>'), [], 0)
        spinAnim.call(() => setCentreText('<span class="spin-countdown">2</span>'), [], 1)
        spinAnim.call(() => setCentreText('<span class="spin-countdown">1</span>'), [], 2)
        spinAnim.call(() => setCentreText('SPIN!'), [], 3)

        spinAnim.to(wheelSegments.current.base, {
            duration: spinLength,
            rotation: resultRotation,
            ease: easeOptions[easeIndex],
            onUpdate: function() {
                updateActiveSegment(this.targets()[0], q('path'), q('.wheel__segments-txt__holder'))
            },
            onComplete: function() {
                setGameState(GAME_STATES.COMPLETE)
            }
        })

        spinAnim.call(() => setCentreText('GOOD LUCK!'), [], 6)
        spinAnim.call(() => setCentreText('YGMI?'), [], spinLength + (2.5 - (easeIndex * 1.1)) - 12)
        spinAnim.call(() => setCentreText('HOLD TIGHT'), [], spinLength + (2.5 - (easeIndex * 1.1)) - 6)
        spinAnim.call(() => setCentreText(`<div class="spin-result">${resultMessage}<span class="spin-result__payout">${payout / LAMPORTS_PER_SOL}</span> SOL</div>`), [], spinLength + (2.5 - (easeIndex * 1.1)))

        spinAnim.play()

        return () => {
            spinAnim.kill()
        }
    }, [gameResult])

    return (
        <>
            <WheelSegments segments={segments} ref={wheelSegments} />

            <div class="wheel__centre" ref={centre} dangerouslySetInnerHTML={{ __html: centreText }}></div>
        </>
    )

}
