import { h } from 'preact'
import { useEffect, useRef, useContext } from 'preact/hooks'
import WheelSegments from './WheelSegments'
import { GameContext } from '../context'
import { GAME_STATES } from '../constants'
import {
    buildIntroSpinAnimation,
    buildIntroLightAnimation
} from './../animation/intro'

export default function({ segments }) {
    const {
        gameState
    } = useContext(GameContext)

    // Refs
    const centre = useRef()
    const wheelSegments = useRef()

    useEffect(() => {
        const introSpinAnim = buildIntroSpinAnimation(centre.current)
        const introLightAnim = buildIntroLightAnimation(wheelSegments.current.base)

        introSpinAnim.play()
        introLightAnim.play()

        return () => {
            introSpinAnim.kill()
            introLightAnim.kill()
        }
    }, [])

    return (
        <>
            <WheelSegments segments={segments} ref={wheelSegments} />

            <div class="wheel__centre" ref={centre}>
                {(() => {
                    switch (gameState) {
                        case GAME_STATES.REQUESTED:
                            return 'NOT LONG NOW'
                        default:
                            return 'SOL SPIN'
                    }
                })()}
            </div>
        </>
    )

}
