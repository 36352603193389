import { h } from 'preact'
import { useContext } from 'preact/hooks'
import { GameContext } from '../context'
import { GAME_STAKES } from '../constants'

export default function() {
    const {
        gameStake,
        setGameStake
    } = useContext(GameContext)

    return (
        <div class="game-option">
            <div class="game-option__title">Select your stake:</div>

            <div class="game-option__group">
                { GAME_STAKES.map(item => {
                    return (
                        <button
                            class={ `btn  ${item !== gameStake ? 'btn--ghost  bg-brand-primary' : 'bg-brand-primary current'}` }
                            onClick={() => setGameStake(item)}
                        >
                            {item}<br/> SOL
                        </button>
                    )
                }) }
            </div>
        </div>
    )

}
