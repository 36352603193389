import { h } from 'preact'
import { useEffect, useRef, useContext } from 'preact/hooks'
import { GameContext } from '../context'

const SEGEMENT_PATHS = [
    'M125.5,125.5V0.5c12.5,0,24.9,1.9,36.8,5.6L125.5,125.5L125.5,125.5',
    'M125.5,125.5L88.7,6.1c11.9-3.7,24.4-5.6,36.8-5.6V125.5L125.5,125.5',
    'M125.5,125.5L55.1,22.2c10.3-7,21.6-12.5,33.6-16.2L125.5,125.5L125.5,125.5',
    'M125.5,125.5L27.8,47.6c7.8-9.8,17-18.3,27.3-25.3L125.5,125.5L125.5,125.5',
    'M125.5,125.5L9.1,79.8C13.7,68.2,20,57.3,27.8,47.6L125.5,125.5L125.5,125.5',
    'M125.5,125.5L0.8,116.2c0.9-12.5,3.7-24.7,8.3-36.3L125.5,125.5L125.5,125.5',
    'M125.5,125.5L3.6,153.3c-2.8-12.2-3.7-24.7-2.8-37.2L125.5,125.5L125.5,125.5',
    'M125.5,125.5L17.2,188C11,177.2,6.4,165.5,3.6,153.3L125.5,125.5L125.5,125.5',
    'M125.5,125.5l-85,91.6c-9.2-8.5-17-18.3-23.2-29.1L125.5,125.5L125.5,125.5',
    'M125.5,125.5L71.3,238.1c-11.3-5.4-21.6-12.5-30.8-21L125.5,125.5L125.5,125.5',
    'M125.5,125.5l-18.6,123.6c-12.4-1.9-24.4-5.6-35.6-11L125.5,125.5L125.5,125.5',
    'M125.5,125.5l18.6,123.6c-12.4,1.9-24.9,1.9-37.3,0L125.5,125.5L125.5,125.5',
    'M125.5,125.5l54.2,112.6c-11.3,5.4-23.3,9.1-35.6,11L125.5,125.5L125.5,125.5',
    'M125.5,125.5l85,91.6c-9.2,8.5-19.5,15.6-30.8,21L125.5,125.5L125.5,125.5',
    'M125.5,125.5L233.8,188c-6.2,10.8-14.1,20.6-23.2,29.1L125.5,125.5L125.5,125.5',
    'M125.5,125.5l121.9,27.8c-2.8,12.2-7.4,23.9-13.6,34.7L125.5,125.5L125.5,125.5',
    'M125.5,125.5l124.7-9.3c0.9,12.5,0,25-2.8,37.2L125.5,125.5L125.5,125.5',
    'M125.5,125.5l116.4-45.7c4.6,11.6,7.4,23.9,8.3,36.3L125.5,125.5L125.5,125.5',
    'M125.5,125.5l97.7-77.9c7.8,9.8,14.1,20.6,18.6,32.3L125.5,125.5L125.5,125.5',
    'M125.5,125.5l70.4-103.3c10.3,7,19.5,15.6,27.3,25.3L125.5,125.5L125.5,125.5',
    'M125.5,125.5L162.3,6.1c11.9,3.7,23.3,9.1,33.6,16.2L125.5,125.5L125.5,125.5',
]

const SEGMENT_COLOURS = {
    green: '#55ffe1',
    purple: '#af3dff',
    pink: '#ff3b94',
    black: '#000000',
    blue: '#1F51FF'
}

const SEGMENT_WIDTH = 360 / SEGEMENT_PATHS.length

const ROTATION_OFFSET = SEGMENT_WIDTH / 2

export default ({ segments }) => {
    const { gameRisk } = useContext(GameContext)
    const wheelSegments = useRef()

    useEffect(() => {
        wheelSegments.current.style = ''
    }, [gameRisk])

    return (
        <div class="wheel__segments" ref={wheelSegments}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 251 251">
                <g style={{ transform: `rotate(${-ROTATION_OFFSET}deg)`, transformOrigin: '50% 50%' }}>
                    { SEGEMENT_PATHS.map((path, index) => {
                        const { color } = segments[index]

                        return (
                            <path
                                d={path}
                                stroke="#222"
                                fill={ SEGMENT_COLOURS[color] }
                            />
                        )
                    }) }
                </g>
            </svg>

            <div class="wheel__segments-txt">
                { segments.map((segment, index) => {
                    return (
                        <div class="wheel__segments-txt__holder" style={ `transform: rotate(${-SEGMENT_WIDTH * index}deg)` }>
                            <div
                                dangerouslySetInnerHTML={{ __html: segment.html }}
                            />
                        </div>
                    )
                }) }
            </div>
        </div>
    )

}
