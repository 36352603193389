export default [
    {
        html: '<span>10x</span> STAKE',
        color: 'purple',
        result: 10,
        resultMessage: 'WOW!'
    },
    {
        html: 'BANKRUPT',
        color: 'black',
        result: 0,
        resultMessage: 'OUCH!'
    },
    {
        html: 'YNGMI',
        color: 'green',
        result: 0,
        resultMessage: 'OUCH!'
    },
    {
        html: 'BANKRUPT',
        color: 'black',
        result: 0,
        resultMessage: 'OUCH!'
    },
    {
        html: 'YNGMI',
        color: 'green',
        result: 0,
        resultMessage: 'OUCH!'
    },
    {
        html: 'BANKRUPT',
        color: 'black',
        result: 0,
        resultMessage: 'OUCH!'
    },
    {
        html: 'YNGMI',
        color: 'green',
        result: 0,
        resultMessage: 'OUCH!'
    },
    {
        html: '<span>5x</span> STAKE',
        color: 'pink',
        result: 5,
        resultMessage: 'WIN!'
    },
    {
        html: 'BANKRUPT',
        color: 'black',
        result: 0,
        resultMessage: 'OUCH!'
    },
    {
        html: 'YNGMI',
        color: 'green',
        result: 0,
        resultMessage: 'OUCH!'
    },
    {
        html: 'BANKRUPT',
        color: 'black',
        result: 0,
        resultMessage: 'OUCH!'
    },
    {
        html: 'YNGMI',
        color: 'green',
        result: 0,
        resultMessage: 'OUCH!'
    },
    {
        html: 'BANKRUPT',
        color: 'black',
        result: 0,
        resultMessage: 'OUCH!'
    },
    {
        html: 'YNGMI',
        color: 'green',
        result: 0,
        resultMessage: 'OUCH!'
    },
    {
        html: '<span>5x</span> STAKE',
        color: 'pink',
        result: 5,
        resultMessage: 'WIN!'
    },{
        html: 'BANKRUPT',
        color: 'black',
        result: 0,
        resultMessage: 'OUCH!'
    },
    {
        html: 'YNGMI',
        color: 'green',
        result: 0,
        resultMessage: 'OUCH!'
    },
    {
        html: 'BANKRUPT',
        color: 'black',
        result: 0,
        resultMessage: 'OUCH!'
    },
    {
        html: 'YNGMI',
        color: 'green',
        result: 0,
        resultMessage: 'OUCH!'
    },
    {
        html: 'BANKRUPT',
        color: 'black',
        result: 0,
        resultMessage: 'OUCH!'
    },
    {
        html: 'YNGMI',
        color: 'green',
        result: 0,
        resultMessage: 'OUCH!'
    }
]
